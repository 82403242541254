<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.mrank_code" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.mrank_code" 
                    :rules="toValidate(mrValidation.mrank_code)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.mrank_name"/>
                  <VValidate 
                    name="Nama" 
                    v-model="row.mrank_name" 
                    :rules="toValidate(mrValidation.mrank_name)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Bangsal<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Pilih Bangsal" v-model="row.mrank_bangsal" :options="$parent.mRanapBangsal" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                  <VValidate 
                    name="Bangsal" 
                    v-model="row.mrank_bangsal" 
                    :rules="toValidate(mrValidation.mrank_bangsal)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Kelas<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Pilih Kelas" v-model="row.mrank_kelas" :options="$parent.mRanapKelas" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                  <VValidate 
                    name="Kelas" 
                    v-model="row.mrank_kelas" 
                    :rules="toValidate(mrValidation.mrank_kelas)"
                  />
                </b-form-group>
              </b-col>
              
              
              <b-col lg="6">
                <b-form-group>
                  <label>Tarif<span class="text-danger mr5">*</span></label>
                  <div class="input-group">
                      <span class="input-group-prepend">
                      <span class="input-group-text">Rp</span>
                      </span>
                      <money v-bind="$parent.money" v-model="row.mrank_tarif" class="form-control" placeholder="e.g 1000.0000" />
                  </div>  
                  <VValidate 
                    name="Tarif" 
                    v-model="row.mrank_tarif" 
                    :rules="toValidate(mrValidation.mrank_tarif)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Keterangan</label>
                  <b-textarea v-model="row.mrank_ket" name="patientNotPresent" id="patientNotPresent" rows="3"
                      class="form-control" placeholder="cth. pasien tidak hadir setelah dipanggil 3x"></b-textarea>
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Identifikasi Ruangan Satu Sehat <span class="text-danger mr5">*</span></label>
                  <b-form-input :disabled="row.disabled_identifier" type="text" class="form-control" v-model="row.mrank_satu_sehat_identifier" placeholder="cth. K-1-P" :formatter="$parent.identifier" />
                </b-form-group>
                <small class="text-info">Pastikan untuk Identifikasi ruangan sudah benar, dikarenakan tidak bisa diedit kembali</small>
              </b-col> 
              
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import {Money} from 'v-money'

export default{
  components:{
    Money
  },
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods: {
    toValidate(val){
      return {...val}
    }
  }
}
</script>